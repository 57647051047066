import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';
import getInvalidOfferPresent from '../selectors/getInvalidOfferPresent';
import getInvalidStagedPurchasePresent from '../selectors/getInvalidStagedPurchasePresent';
import combineStyles from '../utils/combineStyles';
import StandardButton from './StandardButton';
import PaymentRequestButton from './PaymentRequestButton';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import lodash from 'lodash';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import getCurrentSavedCardToken from '../selectors/getCurrentSavedCardToken';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { enqueueErrorSnackbar } from '../utils/snackbar';
import { validate } from '../thunks/validate';
import { loadStripe } from '@stripe/stripe-js';
import { setCheckoutInProgress } from '../slices/checkoutInProgress';
import { purchase } from '../utils/analytics';
import { ApiResponse } from 'libs/polygon-ordering/src/utils/Api';
import getProfile from '../selectors/getProfile';
import { getFilteredRootCategory } from '../libs/polygon-ordering/src/selectors';

const {
  getOrderTotals,
  getOrderReadyToSubmit,
  getAvailablePaymentMethods,
  getSelectedPaymentMethods,
  getTotalCoveredBySubPayments,
  getCartContainsSoldOutItems,
  getCartContainsInvalidQuantities,
  getFreeOrderReadyToSubmit,
  getShowMaxValue,
  getPurchaser,
  getPaymentGatewayConfig,
  getMember,
  getEnableDynamicPaymentGatewayConfig,
  getStagedPurchases,
  getGuestOrderingAvailable,
  getEnableStripeCvcRevalidation,
  getBrands,
  getOpenPurchase,
} = OrderingSelectors;

const { addSelectedPaymentMethod } = OrderingOperations;

const PAYMENT_GATEWAY_METHODS = [PAYMENT_METHOD.CREDIT_CARD, PAYMENT_METHOD.SAVED_CARD];

const { sale, updateSelectedPaymentMethod, finaliseSale } = OrderingOperations;

interface IProps {
  containerStyle?: React.CSSProperties;
}

const processId = (a?: number) => (a ? String(a) : undefined);

const CheckoutButton: React.FC<IProps> = ({ containerStyle }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const stagedPurchases = useAppSelector(getStagedPurchases);
  const enableDynamicPaymentGatewayConfig = useAppSelector(getEnableDynamicPaymentGatewayConfig);
  const member = useAppSelector(getMember);
  const paymentGatewayConfig = useAppSelector(getPaymentGatewayConfig);
  const orderReadyToSubmit = useAppSelector(getOrderReadyToSubmit);
  const availablePaymentMethods = useAppSelector(getAvailablePaymentMethods);
  const selectedPaymentMethods = useAppSelector(getSelectedPaymentMethods);
  const invalidOfferPresent = useAppSelector(getInvalidOfferPresent);
  const invalidStagedPurchasePresent = useAppSelector(getInvalidStagedPurchasePresent);
  const totalCoveredBySubPayments = useAppSelector(getTotalCoveredBySubPayments);
  const creditCardComplete = useAppSelector(state => state.creditCardComplete);
  const checkoutInProgress = useAppSelector(state => state.checkoutInProgress);
  const cartContainsSoldOutItems = useAppSelector(getCartContainsSoldOutItems);
  const cartContainsInvalidQuantities = useAppSelector(getCartContainsInvalidQuantities);
  const freeOrderReadyToSubmit = useAppSelector(getFreeOrderReadyToSubmit);
  const enableCvcCheck = useAppSelector(getEnableStripeCvcRevalidation);
  const cvcFilled = useAppSelector(state => state.cvcFilled);
  const currentSavedCardToken = useAppSelector(getCurrentSavedCardToken);
  const elements = useElements();
  const stripe = useStripe();
  const orderTotals = useAppSelector(getOrderTotals);
  const maxValueReached = useAppSelector(getShowMaxValue);
  const payment_gateway = selectedPaymentMethods.filter(options =>
    PAYMENT_GATEWAY_METHODS.includes(options.method),
  )[0];
  const purchaser = useAppSelector(getPurchaser);
  const profile = useAppSelector(getProfile);
  const guestOrderingAvailable = useAppSelector(getGuestOrderingAvailable);
  const maxOrderValueReached = useAppSelector(getShowMaxValue);
  const brands = useAppSelector(getBrands);
  const rootCategory = useAppSelector(getFilteredRootCategory);
  const categories = rootCategory?.subCategories || [];
  const openPurchase = useAppSelector(getOpenPurchase);

  const fullName = `${purchaser.name || ''} ${purchaser.familyName || ''}`.trim();

  const includesSavedCard =
    selectedPaymentMethods.filter(method => method.method === PAYMENT_METHOD.SAVED_CARD).length > 0;

  const ccMissing =
    lodash.find(selectedPaymentMethods, ['method', PAYMENT_METHOD.CREDIT_CARD]) &&
    !creditCardComplete;

  const gcMissingOrInvalid =
    lodash.find(selectedPaymentMethods, ['method', PAYMENT_METHOD.GIFT_CARD]) &&
    !availablePaymentMethods.includes(PAYMENT_METHOD.GIFT_CARD);

  const noSelectedPaymentMethods = selectedPaymentMethods.length === 0;

  const mustBeVerifiedMember = !profile?.verified && !guestOrderingAvailable;

  const paymentRequestButtonMethodSelected = selectedPaymentMethods.some(selectedPaymentMethod => {
    if (
      selectedPaymentMethod.method === PAYMENT_METHOD.APPLE_PAY ||
      selectedPaymentMethod.method === PAYMENT_METHOD.GOOGLE_PAY
    ) {
      return true;
    }
    return false;
  });

  const showPaymentRequestButton =
    paymentRequestButtonMethodSelected && orderReadyToSubmit && !gcMissingOrInvalid;

  const handleCheckout = () => {
    dispatch(setCheckoutInProgress(true));
    checkout();
  };

  const checkout = async () => {
    const { proceedToFinalise } = await dispatch(validate()).unwrap();

    if (proceedToFinalise) {
      try {
        let subPayment = { ...payment_gateway };
        if (freeOrderReadyToSubmit) {
          dispatch(
            addSelectedPaymentMethod({
              method: PAYMENT_METHOD.FREE,
              amount: 0,
            }),
          );
        } else {
          switch (payment_gateway?.method) {
            case PAYMENT_METHOD.CREDIT_CARD: {
              const cardElement = elements?.getElement(CardElement);

              let token = '';
              let last4 = '';

              if (enableDynamicPaymentGatewayConfig) {
                const { paymentMethod, error } = await stripe!.createPaymentMethod({
                  type: 'card',
                  card: cardElement!,
                  billing_details: {
                    name: fullName,
                    email: purchaser.email!,
                    phone: purchaser.mobile!,
                  },
                });

                console.log(paymentMethod, enableDynamicPaymentGatewayConfig, 'CHECKOUT');

                if (error) {
                  throw error?.message;
                }

                token = paymentMethod?.id || '';
                last4 = paymentMethod?.card?.last4 || '';
              } else {
                const { token: cardToken, error } = await stripe!.createToken(cardElement!, {
                  name: fullName,
                });

                if (error) {
                  throw error?.message;
                }

                token = cardToken?.id || '';
                last4 = cardToken?.card?.last4 || '';
              }

              subPayment.token = token;
              subPayment.lastFour = last4;
              break;
            }

            case PAYMENT_METHOD.SAVED_CARD: {
              subPayment.token = currentSavedCardToken!;
              break;
            }
            default:
          }
        }

        if (
          payment_gateway?.method === PAYMENT_METHOD.CREDIT_CARD ||
          payment_gateway?.method === PAYMENT_METHOD.SAVED_CARD
        ) {
          //update payment method with token details
          dispatch(updateSelectedPaymentMethod(subPayment));
        }

        const response = (await dispatch(
          sale({
            route: 'checkout',
            authenticationMethod: member ? 'member' : 'none',
          }),
        ).unwrap()) as ApiResponse;

        if (response.success) {
          checkoutSuccessful(
            processId(
              response.data.TransactionID || response.data.ID || response.data.transactionId,
            ),
          );
        } else {
          if (response.error_code === 206) {
            handle3ds(response.additional_info);
          } else {
            enqueueErrorSnackbar(response.error);
            dispatch(setCheckoutInProgress(false));
          }
        }
      } catch (err: any) {
        enqueueErrorSnackbar(err.e || err.message);
        dispatch(setCheckoutInProgress(false));
        dispatch(setCheckoutInProgress(false));
      }
    } else {
      dispatch(setCheckoutInProgress(false));
    }
  };

  const handle3ds = async (additional_info: any) => {
    if (!paymentGatewayConfig) {
      enqueueErrorSnackbar(
        'Missing paymentGatewayConfig. Please check payment gateway has been configured',
      );
      dispatch(setCheckoutInProgress(false));
    }

    // NOTE: the backend clones cc payments to the proper merchant accounts, this is a stripe-intended hacky workaround to get credit card details to save across multiple merchant accounts
    // this is why we make a new stripe instance with the specific merchant account here to do 3ds validation, as 3ds will use the cloned payment within the merchant account, not the one we sent to the main stripe account
    const stripe2 = await loadStripe(paymentGatewayConfig!.clientKey, {
      stripeAccount: paymentGatewayConfig?.merchantAccount,
    });

    const result = await stripe2?.handleCardAction(additional_info.action.client_secret);

    if (result) {
      const { paymentIntent, error } = result;

      if (error) {
        enqueueErrorSnackbar(error.message || '3DS Failed');
        dispatch(setCheckoutInProgress(false));

        return;
      }

      const finaliseResponse = await dispatch(
        finaliseSale({
          additional_data: {
            orderInfo: additional_info.orderInfo,
            actionResponse: {
              payment_intent_id: paymentIntent?.id!,
            },
          },
          authenticationMethod: member ? 'member' : 'none',
        }),
      );

      if (finaliseResponse.meta.requestStatus === 'rejected') {
        enqueueErrorSnackbar(
          finaliseResponse.payload.e ||
            finaliseResponse.payload.e.message ||
            finaliseResponse.payload.reason,
        );
      } else {
        checkoutSuccessful(
          processId(
            finaliseResponse.payload.TransactionID ||
              finaliseResponse.payload.ID ||
              finaliseResponse.payload.transactionId,
          ),
        );
      }
    }
  };

  const checkoutSuccessful = (transactionId?: string) => {
    dispatch(setCheckoutInProgress(false));

    purchase(
      stagedPurchases.map(purchaseItem => {
        const category =
          categories.find(category =>
            lodash.map(category.items, 'id').find(id => id === purchaseItem.item.id),
          )?.name || null;

        const brand = brands.find(({ id }) => purchaseItem.brandId === id)?.name || null;
        return {
          ...purchaseItem.item,
          quantity: purchaseItem.quantity,
          totalPrice: purchaseItem.moneyPrice,
          choicesWithQuantity: purchaseItem.choicesWithQuantity,
          category,
          brand,
        };
      }),
      orderTotals?.discountedMoneyPrice!,
      transactionId,
      brands.find(brand => brand.id === openPurchase?.brandId)?.name,
    );
  };

  return showPaymentRequestButton ? (
    <div style={styles.paymentRequestButtonContainer}>
      {/* This should be apple pay or google pay stripe elements to show the options */}
      <PaymentRequestButton />
    </div>
  ) : (
    // This should be the standard payment
    <StandardButton
      themeKey="checkoutButton"
      label={t('button.checkout')}
      onClick={() => handleCheckout()}
      disabled={
        maxValueReached ||
        checkoutInProgress ||
        ccMissing ||
        gcMissingOrInvalid ||
        !orderReadyToSubmit ||
        mustBeVerifiedMember ||
        (freeOrderReadyToSubmit ? false : noSelectedPaymentMethods) ||
        !totalCoveredBySubPayments ||
        cartContainsSoldOutItems ||
        //@ts-ignore
        cartContainsInvalidQuantities.cartContainsInvalidQuantities ||
        (includesSavedCard && enableCvcCheck && !cvcFilled) ||
        maxOrderValueReached
      }
      showSpinner={checkoutInProgress}
      containerStyle={combineStyles(styles.button, containerStyle)}
      LeftIconComponent={
        invalidOfferPresent || invalidStagedPurchasePresent ? RiAlertFill : undefined
      }
    />
  );
};

const styles: Styles = {
  button: {
    marginTop: 20,
  },
  paymentRequestButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default CheckoutButton;
